/* General container for home page */
.home-container {
    font-family: 'Arial', sans-serif;
    color: #333;
    padding: 20px;
    background-color: #f4f4f4;
    margin: 0 auto;
    max-width: 1200px; /* Đặt max-width để giới hạn chiều rộng của trang */
    text-align: center; /* Căn giữa nội dung bên trong */
  }
  
  /* Header section */
  .home-header {
    margin-bottom: 40px;
  }
  
  .home-header h1 {
    font-size: 3rem;
    color: #2a2a2a;
    margin-bottom: 10px;
  }
  
  .home-header p {
    font-size: 1.2rem;
    color: #555;
  }
  
  /* Mission section */
  .mission-section {
    background-color: #f18c8c;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
    max-width: 800px;
    margin: 0 auto; /* Căn giữa */
  }
  
  .mission-section h2 {
    font-size: 2rem;
    color: #2a2a2a;
    margin-bottom: 20px;
  }
  
  .mission-section p {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.6;
  }
  
  /* Benefits section */
  .benefits-section {
    padding: 40px;
    text-wrap: center;
    margin-bottom: 40px;
  }
  
  .benefits-section h2 {
    font-size: 2rem;
    color: #2a2a2a;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .benefits-section ul {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.6;
    text-align: justify; /* Căn đều văn bản */
    padding-left: 0; /* Loại bỏ lề trái mặc định */
  }
  
  .benefits-section li {
    font-size: 1.2rem;
    color: #444;
    margin-bottom: 10px;
  }
  
  .benefits-section strong {
    color: #2a2a2a;
  }
  
  /* Call-to-action section */
  .cta-section {
    text-align: center;
    background-color: #2a9d8f;
    color: rgb(213, 238, 143);
    padding: 30px;
    border-radius: 8px;
    max-width: 800px;
    margin: 0 auto; /* Căn giữa */
  }
  
  .cta-section h2 {
    font-size: 2.2rem;
    margin-bottom: 15px;
  }
  
  .cta-section p {
    font-size: 1.2rem;
  }
  
  .cta-section a {
    text-decoration: none;
    background-color: #e76f51;
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
  }
  
  .cta-section a:hover {
    background-color: #f4a261;
  }

  /* Video section */
.video-section {
    text-align: center; /* Căn giữa tiêu đề và video */
    margin: 40px 0;
  }
  
  .video-section h2 {
    font-size: 2rem;
    color: #2a2a2a;
    margin-bottom: 20px;
  }
  
  .video-section p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .video-container {
    display: flex;
    justify-content: center; /* Căn giữa video */
    margin-top: 20px;
  }
  
  .video-container iframe {
    max-width: 100%;
    width: 800px; /* Đặt chiều rộng tối đa của video */
    height: 450px; /* Đặt chiều cao của video */
    border-radius: 8px; /* Tạo hiệu ứng bo góc cho video */
  }

  .footer {
    background-color: #f8f8f8;
    padding: 20px;
    text-align: center;
  }
  
  .sponsor-logo img {
    max-width: 120px;
    margin-bottom: 10px;
  }
  
  .contact-info {
    font-size: 14px;
    color: #333;
  }
  
  .social-media a {
    margin: 0 10px;
    display: inline-block;
  }
  
  .social-media img {
    width: 30px;
    height: 30px;
  }
  
  .copyright {
    font-size: 12px;
    color: #777;
    margin-top: 15px;
  }
  
  
  