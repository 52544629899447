.music-room {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #d162e7(0, 0, 0, 0.7);
    color: #f5f5f5;
    padding: 20px;
    border-radius: 20px;
    max-width: 500px;
    margin: 100px auto;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

.music-player {
    background-color: rgba(237, 104, 241, 0.4);
    padding: 5px;
    border-radius: 10px;
    text-align: center;
    width: 100%;
}

.track-info {
    margin-bottom: 10px;
    font-size: 18px;
    text-align: center;
}

.controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.controls button {
    background-color: #f000f8;
    border: none;
    color: #0e0d0d;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.controls button:hover {
    background-color: #f000f8;
}

.controls button:disabled {
    background-color: #f057c2;
    cursor: not-allowed;
}

.audio-player {
    width: 100%;
    margin-top: 10px;
}

#p5-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    pointer-events: none;
}