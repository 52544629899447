/* Body toàn màn hình với hiệu ứng nền gradient galaxy */
body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background: linear-gradient(135deg, #1b2735 0%, #090a0f 100%);
    color: #ffffff;
    overflow-x: hidden;
  }
  
  /* Hiệu ứng canvas p5.js toàn màn hình */
  canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Đưa canvas xuống dưới cùng */
  }
  
  /* Cấu hình container chính */
  .app-container {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  /* Container cho phần mint NFT */
  .container {
    position: absolute;
    top: 25%; /* Bắt đầu ở giữa màn hình */
    left: 50%;
    transform: translate(-50%, -50%);
    transition: top 0.3s ease; /* Chuyển động mượt mà */
    max-width: 800px;
    width: 100%;
    padding: 20px;
    margin: 170px auto;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
    z-index: 1; /* Đưa nội dung lên trên */
  }
  
  /* Phong cách tiêu đề chính */
  h1 {
    text-align: center;
    margin: 20px 0 30px;
    font-size: 2.5em;
    color: #ffffff;
    text-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
  }
  
  /* Phong cách form */
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* Phong cách cho input và textarea */
  input, textarea {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    font-size: 1em;
    transition: background 0.3s;
  }
  
  input:focus, textarea:focus {
    background: rgba(255, 255, 255, 0.2);
    outline: none;
  }
  
  /* Input file có phong cách tương tự */
  input[type="file"] {
    padding: 5px;
    cursor: pointer;
  }
  
  input[type="file"]:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  
  /* Phong cách cho button */
  button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: #4e89ff;
    color: #ffffff;
    font-size: 1.2em;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
  }
  
  button:hover {
    background: #3b6fd8;
    transform: scale(1.05);
  }
  
  button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  /* Chống việc chỉnh kích thước textarea */
  textarea {
    resize: none;
  }
  