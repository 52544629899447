/* Container và lưới NFT */
.marketplace-container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
}

.nft-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 26px;
    justify-items: center;
    margin-top: 20px;
}

.nft-card {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    width: 150px;
    background-color: #e93096;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    margin: 1px;
    gap: 5px;
}

.nft-card h2 {
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    width: 100%; 
    font-size: 18px; 
    text-align: center; 



}


.nft-card:hover {
    transform: scale(1.05);
}

.nft-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
}



.nft-detail-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.nft-detail {
    background-color: #3f76ec;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
    position: relative;
}

.nft-detail-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.close-detail {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #666;
    font-weight: bold;
}

.rating {
    margin-top: auto; /* Đẩy cả cụm rating xuống dưới cùng */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px; /* Khoảng cách giữa chữ Rating và các ngôi sao */
    font-size: 16px;
    color: #fff; /* Màu chữ cho chữ "Rating" */
}

.star {
    font-size: 20px;
    color: #e4e3de;
    
}

.star.filled {
    color: #09f344;
}
.star.filled.yellow {
    color: yellow; /* Màu vàng cho 3 sao */
  }
  
  .star.filled.purple {
    color: purple; /* Màu tím cho 4 sao */
  }
  
  .star.filled.red {
    color: red; /* Màu đỏ cho 5 sao */
  }


button {
    margin-top: 15px;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #28a745;
    color: white;
    font-weight: bold;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #218838;
}

button.close-detail {
    background-color: #dc3545;
}

button.close-detail:hover {
    background-color: #c82333;
}

.verified-icon {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    vertical-align: middle;
}

.search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    padding: 10px;
}

/* Kiểu dáng cho trường nhập liệu tìm kiếm */
.search-container input[type="text"] {
    width: 300px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px 0 0 4px;
    outline: none;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

/* Đổi màu viền khi người dùng focus vào trường nhập */
.search-container input[type="text"]:focus {
    border-color: #007BFF; /* Màu xanh nhạt */
}

/* Kiểu dáng cho nút tìm kiếm */
.search-container button {
    padding: 10px 20px;
    background-color: #007BFF; /* Màu xanh đậm cho nút */
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 15px;
}

/* Hiệu ứng khi người dùng hover vào nút tìm kiếm */
.search-container button:hover {
    background-color: #0056b3; /* Màu xanh đậm hơn khi hover */
}