/* Navigation Bar */
nav {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

/* Style for navigation links */
nav a {
  margin: 0 10px;
  font-size: 1rem;
  color: #fcfcfc;  /* Màu chữ mặc định */
  text-decoration: none; /* Loại bỏ gạch chân */
  transition: color 0.3s ease; /* Hiệu ứng chuyển màu khi hover */
}

/* Hover effect for navigation links */
nav a:hover {
  color: #2a9d8f; /* Màu chữ khi hover */
}

/* Active link style (current page) */
nav a.active {
  color: #e76f51; /* Màu chữ khi trang hiện tại */
}

/* Style for the wallet connection button */
button {
  margin-left: 20px;
  padding: 5px 10px;
  background-color: #2a9d8f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Hover effect for the wallet connection button */
button:hover {
  background-color: #e76f51;
}
