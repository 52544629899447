.profile-container {
    padding: 20px;
}

.nft-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 26px;
}


.nft-details {
    text-align: left;
}

.verified-icon {
    width: 20px;
    height: 20px;
    margin-left: 5px;
}

.rating {
    margin: 10px 0;
}

.star {
    font-size: 20px;
    color: gray;
}

.star.filled {
    color: green;
}

.star.filled.yellow {
    color: yellow;
}

.star.filled.purple {
    color: purple;
}

.star.filled.red {
    color: red;
}

.sell-section {
    margin-top: 10px;
    display: flex;
    gap: 10px;
}

.sell-section input {
    flex: 1;
    padding: 5px;
}

button {
    padding: 10px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}

.nft-card {
    border: 1px solid #fd0202;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    width: 150px;
    background-color: #e93096;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    margin: 1px;
    gap: 5px;
    
}

.nft-card h2 {
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    width: 100%; 
    font-size: 18px; 
    text-align: center; 



}


.nft-card:hover {
    transform: scale(1.05);
}

.nft-tooltip {
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    text-align: left;
    padding: 10px;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    top: 50%; 
    left: 100%; 
    transform: translateY(-50%); 
    white-space: nowrap; 
    margin-left: 10px; 
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.nft-card:hover .nft-tooltip {
    visibility: visible;
    opacity: 1;
}

.nft-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
}

.sell-section input {
    width: 80px;
    margin-right: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 2px 5px;
}

.sell-section button {
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.sell-section button:hover {
    background-color: #218838;
}

button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
.nft-detail-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.nft-detail {
    background-color: #3f76ec;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
    position: relative;
}

.nft-detail-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.close-detail {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #666;
    font-weight: bold;
}

.rating {
    margin-top: auto; /* Đẩy cả cụm rating xuống dưới cùng */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px; /* Khoảng cách giữa chữ Rating và các ngôi sao */
    font-size: 16px;
    color: #fff; /* Màu chữ cho chữ "Rating" */
}

.star {
    font-size: 20px;
    color: #e4e3de;
    
}

.star.filled {
    color: #09f344;
}
.star.filled.yellow {
    color: yellow; /* Màu vàng cho 3 sao */
  }
  
  .star.filled.purple {
    color: purple; /* Màu tím cho 4 sao */
  }
  
  .star.filled.red {
    color: red; /* Màu đỏ cho 5 sao */
  }


button {
    margin-top: 15px;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #28a745;
    color: white;
    font-weight: bold;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #218838;
}

button.close-detail {
    background-color: #dc3545;
}

button.close-detail:hover {
    background-color: #c82333;
}

.verified-icon {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    vertical-align: middle;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal content */
.modal-content {
    background: rgb(64, 154, 238);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 90%;
    text-align: left;
}

.modal-content h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.modal-content label {
    display: block;
    margin: 10px 0 5px;
    font-weight: bold;
}

.modal-content input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.button-group {
    display: flex;
    justify-content: space-between;
}

.button-group button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button-group button:first-child {
    background-color: #4caf50;
    color: white;
}

.button-group button:last-child {
    background-color: #f44336;
    color: white;
}

.request-verification-btn {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.request-verification-btn:hover {
    background-color: #0056b3;
}